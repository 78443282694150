export const getLanguageCode = code => code

export const getCodeForLanguageName = name => {
  switch (name) {
    case "Nederlands":
      return "NL"
    case "Deutsch":
      return "DE"
    case "Français":
      return "FR"
    case "English":
      return "EN"
    default:
      return "EN"
  }
}

export const dropdownLanguageOptions = [
  { value: getLanguageCode("FR").toLowerCase(), name: getLanguageCode("FR") },
  { value: getLanguageCode("EN").toLowerCase(), name: getLanguageCode("EN") },
  { value: getLanguageCode("DE").toLowerCase(), name: getLanguageCode("DE") },
  { value: getLanguageCode("NL").toLowerCase(), name: getLanguageCode("NL") },
]

export const getLanguageName = code => {
  switch (code) {
    case "NL":
      return "Nederlands"
    case "DE":
      return "Deutsch"
    case "FR":
      return "Français"
    case "EN":
      return "English"
    default:
      return "English"
  }
}
